import styled from "styled-components";
import Column from "src/kit/Column";
import Row from "src/kit/Row";

export const HyperLedgerFabricContainer = styled(Column)`
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    height: calc(100% - 50px);
    box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.10);
    margin-bottom :20px;
    justify-content: space-between;
    overflow-y: auto;

    .add-container{
        background-color : ${(props) =>props.theme.primaryDark};
        padding :10px;
        width : max-content;
        border-radius :${(props) =>props.theme.borderRadius} ;
        display :flex;
        flex-direction : row;
        height : auto;
        color : ${(props) =>props.theme.white};
    }

    form {
      width :100%;
    }

`;
export const FormContainer = styled(Column)`
  padding : 10px 20px;
  margin-bottom : 20px;
  width: 100%;
  box-sizing: border-box;
  border :1px solid #FFCF81;
  border-radius: ${(props) => props.theme.borderRadius};
  form {
   width: 100%;
  }

`;