import {
  all,
  takeEvery,
  put,
  fork,
} from "redux-saga/effects";
import types from "./Types";
import Endpoints from "./Endpoints";
import Api from "../../services/Api";
import { toast } from 'react-toastify';
import { loginCode } from "src/utils/loginCode";
import { downloadFile } from "src/utils/methods/DownloadFileHandler";


export function* GetEndpointsList() {
  yield takeEvery(types.GET_ENDPOINTS_LIST, function* ({ data }) {
    let body = {
      "network": "",
      "endpointName": data !== undefined ? data.title : "",
      "page": data.page,
      "pageSize": data.pageSize
    };
    try {
      const response = yield Api.Post(Endpoints.GetEndpointsList, body, true);

      if (response) {
        yield put({
          type: types.GET_ENDPOINTS_LIST_SUCCESS,
          data: { data: response, count: response.nodes.totalItemsCount , skip : data.skip ,page:data.page },
        });
      } else
        yield put({
          type: types.GET_ENDPOINTS_LIST_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? selected[0].title : e.response.data.title, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      yield put({
        type: types.GET_ENDPOINTS_LIST_FAIL,
      });
    }
  });
}

export function* AddEndpoint() {
  yield takeEvery(types.ADD_ENDPOINT, function* ({ data }) {

    let body = {
      "name": data.fields.title,
      // "networkId": data.fields.network,
      "endpointId": data.fields.blockchain
    }
    try {
      const response = yield Api.Post(Endpoints.AddEndpoint, body, true);
      if (response) {
        yield put({
          type: types.ADD_ENDPOINT_SUCCESS,
          data: { data: response },
        });
        data?.callBack();
        toast.success("با موفقیت انجام شد", { position: toast.POSITION.BOTTOM_RIGHT });

      } else
        yield put({
          type: types.ADD_ENDPOINT_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? selected[0].title : e.response.data.title, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      yield put({
        type: types.ADD_ENDPOINT_FAIL,
      });
    }
  });
}

export function* GetEndpointById() {
  yield takeEvery(types.GET_ENDPOINT_BY_ID, function* ({ data }) {
    try {
      const response = yield Api.Get(Endpoints.GetEndpointById + `?userApiKeyId=${data.id}`, true);
      if (response) {
        yield put({
          type: types.GET_ENDPOINT_BY_ID_SUCCESS,
          data: response,

        });
      } else
        yield put({
          type: types.GET_ENDPOINT_BY_ID_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? selected[0].title : e.response.data.title, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      yield put({
        type: types.GET_ENDPOINT_BY_ID_FAIL,
      });
    }
  });
}

export function* GetDetailsStatusBasedChart() {
  yield takeEvery(types.GET_DETAILS_STATUS_BASED_CHART, function* ({ data }) {
    try {
      const response = yield Api.Get(Endpoints.GetDetailsStatusBasedChart + `?userApiKeyId=${data.id}&timeDuration=${data.time}`, true);
      if (response) {
        yield put({
          type: types.GET_DETAILS_STATUS_BASED_CHART_SUCCESS,
          data: response,

        });
      } else
        yield put({
          type: types.GET_DETAILS_STATUS_BASED_CHART_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? selected[0].title : e.response.data.title, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      yield put({
        type: types.GET_DETAILS_STATUS_BASED_CHART_FAIL,
      });
    }
  });
}

export function* GetBlockchainsList() {
  yield takeEvery(types.GET_BLOCKCHAIN_LIST, function* ({ data }) {

    try {
      const response = yield Api.Get(Endpoints.GetBlockchainsList, true);

      if (response) {
        yield put({
          type: types.GET_BLOCKCHAIN_LIST_SUCCESS,
          data: { data: response },

        });
      } else
        yield put({
          type: types.GET_BLOCKCHAIN_LIST_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? selected[0].title : e.response.data.title, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      yield put({
        type: types.GET_BLOCKCHAIN_LIST_FAIL,
      });
    }
  });
}

export function* GetNetworksList() {
  yield takeEvery(types.GET_NETWORKS_LIST, function* ({ data }) {

    try {
      const response = yield Api.Get(Endpoints.GetNetworksList + `?blockchainId=${data.id}`, true);

      if (response) {
        yield put({
          type: types.GET_NETWORKS_LIST_SUCCESS,
          data: { data: response },

        });
      } else
        yield put({
          type: types.GET_NETWORKS_LIST_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? selected[0].title : e.response.data.title, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      yield put({
        type: types.GET_NETWORKS_LIST_FAIL,
      });
    }
  });
}

export function* GenerateApiKey() {
  yield takeEvery(types.GENERATE_APIKEY, function* ({ data }) {

    try {
      const response = yield Api.Get(Endpoints.GenerateApiKey+ `?userApiKeyId=${data.id}`, true);

      if (response) {
        yield put({
          type: types.GENERATE_APIKEY_SUCCESS,
          data: { data: response },

        });
      } else
        yield put({
          type: types.GENERATE_APIKEY_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? selected[0].title : e.response.data.title, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      yield put({
        type: types.GENERATE_APIKEY_FAIL,
      });
    }
  });
}


export function* DownloadDocument() {
  yield takeEvery(types.DOWNLOAD_DOCUMENT, function* ({ data }) {

    try {
      const response = yield Api.Get(Endpoints.DownloadDocument, true ,"pdf");

      if (response) {
        downloadFile(response , "Polaris WaaS v1.1");
        yield put({
          type: types.DOWNLOAD_DOCUMENT_SUCCESS,
          data: { data: response },
        });
      } else
        yield put({
          type: types.DOWNLOAD_DOCUMENT_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? selected[0].title : e.response.data.title, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      yield put({
        type: types.DOWNLOAD_DOCUMENT_FAIL,
      });
    }
  });
}

export function* GetDetailsCuBasedChart() {
  yield takeEvery(types.GET_DETAILS_CU_BASED_CHART, function* ({ data }) {
    try {
      const response = yield Api.Get(Endpoints.GetDetailsCuBasedChart + `?userApiKeyId=${data.id}&timeDuration=${data.time}`, true);
      if (response) {
        yield put({
          type: types.GET_DETAILS_CU_BASED_CHART_SUCCESS,
          data: response,

        });
      } else
        yield put({
          type: types.GET_DETAILS_CU_BASED_CHART_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? selected[0].title : e.response.data.title, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      yield put({
        type: types.GET_DETAILS_CU_BASED_CHART_FAIL,
      });
    }
  });
}

export function* GetEndpoints() {
  yield takeEvery(types.GET_ENDPOINTS, function* ({ data }) {

    try {
      const response = yield Api.Get(Endpoints.GetEndpoints, true);

      if (response) {
        yield put({
          type: types.GET_ENDPOINTS_SUCCESS,
          data: { data: response },

        });
      } else
        yield put({
          type: types.GET_ENDPOINTS_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? selected[0].title : e.response.data.title, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      yield put({
        type: types.GET_ENDPOINTS_FAIL,
      });
    }
  });
}
export default function* EndpointSagas() {
  yield all(
    [
      fork(GetEndpointsList),
      fork(GetEndpoints),
      fork(AddEndpoint),
      fork(GetEndpointById),
      fork(GetDetailsStatusBasedChart),
      fork(GetBlockchainsList),
      fork(GetNetworksList),
      fork(GenerateApiKey),
      fork(DownloadDocument),
      fork(GetDetailsCuBasedChart)
    ]);
}



