import React, { useEffect } from "react";

import { Box, Grid } from "@mui/material"
import { useLocation, Link } from "react-router-dom";
import LoginActions from "src/redux/Login/Actions";
import { FormContainer } from "./styles";
import { Formik } from "formik";
import FormController from "src/controllers/FormController";
import { FormInput } from "src/kit/Form";
import Button from "src/kit/Button"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const LoginRegisterContent = () => {
    let location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const queryParams = new URLSearchParams(location.search);
    const resetToken = queryParams.get('resetToken');

    return (
        < >
            <FormContainer>

                <Formik
                    initialValues={{
                        email: "",
                        newPassword: "",
                        confirmPassword: ""
                    }}
                    onSubmit={(values, action) => {
                        let callBack = () => {
                            history.push("/");
                        };

                        dispatch(LoginActions.resetPassword({ values: values,resetToken: encodeURIComponent(resetToken), callBack }));
                    }}
                >
                    {(formik) => {
                        return (
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container >
                                    <Grid item xs={12} py={1}>
                                        <FormInput
                                            type="text"
                                            className={
                                                "login-form-control" + (formik.errors.email && formik.touched.email ? " warning-border" : "")
                                            }
                                            value={formik.values.email}
                                            id="email"
                                            name="email"
                                            onChange={(e) => FormController.onChange(e, formik)}
                                            onBlur={(e) => FormController.onBlur(e, formik)}
                                            placeholder="ایمیل"
                                        />
                                    </Grid>
                                    <Grid item xs={12} py={1}>
                                        <FormInput
                                            type="password"
                                            className={
                                                "login-form-control" + (formik.errors.newPassword && formik.touched.newPassword ? " warning-border" : "")
                                            }
                                            value={formik.values.newPassword}
                                            id="newPassword"
                                            name="newPassword"
                                            onChange={(e) => FormController.onChange(e, formik)}
                                            onBlur={(e) => FormController.onBlur(e, formik)}
                                            placeholder="رمز عبور جدید"
                                        />
                                    </Grid>
                                    <Grid item xs={12} py={1}>
                                        <FormInput
                                            type="password"
                                            className={
                                                "login-form-control" + (formik.errors.confirmPassword && formik.touched.confirmPassword ? " warning-border" : "")
                                            }
                                            value={formik.values.confirmPassword}
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            onChange={(e) => FormController.onChange(e, formik)}
                                            onBlur={(e) => FormController.onBlur(e, formik)}
                                            placeholder="تکرار رمز عبور جدید"
                                        />
                                    </Grid>
                                    <Grid item xs={12} py={2}>
                                        <Button
                                            onClick={() => formik.handleSubmit()}
                                            text="ذخیره"
                                        >
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </Formik>

            </FormContainer>
        </>
    )
}
export default LoginRegisterContent;