
import React from "react";
import { Box, Grid, } from "@mui/material";
import { FormInput } from "src/kit/Form";
import { FormContainer } from "../../style";
import FormController from "src/controllers/FormController";
import { Formik } from "formik";
import { VerifyValidationOrderer } from "src/validation/schemas/verifyValidationOrderer"
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import crtl from "../../hyperledger.controllers";

const OrdererSetting = ({}) => {
    const { 
        handleSubmit
      } = crtl();
    const organizationList = useSelector((state) => state.HyperledgerFabric.organizationList);
    const orgNumInCompany = useSelector((state) => state.HyperledgerFabric.orgNumInCompany);

    return (
        <Formik
            initialValues={{
                ordererName: "",
                ordererPort: "",
                ordererType: "solo"
            }}
            validationSchema={VerifyValidationOrderer}
            isInitialValid={false}
            enableReinitialize={true}
            onSubmit={(values, action) => {
                if (Number(orgNumInCompany) > organizationList.length) {
                    toast.error("لیست سازمان ها کمتر از فیلد تعداد سازمان در شبکه می باشد", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                else {
                    handleSubmit(values);
                }
            }}
        >
            {(formik) => (
                <>
                    <FormContainer>
                        <Grid container display="flex" justifyContent="end">
                            <Grid xs={12}>تنظیمات سفارش دهنده</Grid>
                            <Grid item p={2} xs={12} md={4}>
                                <Box className="form_lable">نام نود orderer</Box>
                                <FormInput
                                    value={formik.values.ordererName}
                                    name="ordererName"
                                    id="ordererName"
                                    placeholder="نام نود orderer"
                                    onChange={(e) => FormController.onChange(e, formik, null)}
                                    className={
                                        "custom_input" + (formik.errors.ordererName && formik.touched.ordererName ? " warning-border" : "")
                                    }
                                />
                                {formik.errors.ordererName && formik.touched.ordererName ? (
                                    <div className="text-alert">{formik.errors.ordererName}</div>
                                ) : <div className="text-alert"></div>}

                            </Grid>
                            <Grid item p={2} xs={12} md={4}>
                                <Box className="form_lable">پورت نود orderer</Box>
                                <FormInput
                                    value={formik.values.ordererPort}
                                    name="ordererPort"
                                    id="ordererPort"
                                    placeholder="پورت نود orderer"
                                    onChange={(e) => FormController.onChange(e, formik, null)}
                                    className={
                                        "custom_input" + (formik.errors.ordererPort && formik.touched.ordererPort ? " warning-border" : "")
                                    }
                                />
                                {formik.errors.ordererPort && formik.touched.ordererPort ? (
                                    <div className="text-alert">{formik.errors.ordererPort}</div>
                                ) : <div className="text-alert"></div>}

                            </Grid>
                            <Grid item p={2} xs={12} md={4}>
                                <Box className="form_lable">نوع orderer</Box>
                                <FormInput
                                    value={formik.values.ordererType}
                                    name="ordererType"
                                    id="ordererType"
                                    placeholder="نوع orderer"
                                    onChange={(e) => FormController.onChange(e, formik, null)}
                                    className={
                                        "custom_input" + (formik.errors.ordererType && formik.touched.ordererType ? " warning-border" : "")
                                    }
                                    disabled
                                />
                                {formik.errors.ordererType && formik.touched.ordererType ? (
                                    <div className="text-alert">{formik.errors.ordererType}</div>
                                ) : <div className="text-alert"></div>}

                            </Grid>
                            <Grid item xs={4} p={2} md={4} lg={2}>
                                <button
                                    color="primary"
                                    size="lg"
                                    onClick={() => { formik.handleSubmit(); }}
                                    className="ml-1 btn-form"
                                    type="submit"
                                >
                                    <Box className="color-white" p={2}>بعدی</Box>
                                </button>
                            </Grid>
                        </Grid>
                    </FormContainer>
                </>
            )}
        </Formik>
    )
}
export default OrdererSetting;