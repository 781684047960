import types from "./Types";
import { prepareSelectData } from "src/utils/index"
import RecordController from "src/controllers/RecordController";

const initState = {
  showEndpointDetailModal: false,
  endpointsList: [],
  endpointLoading: true,
  takeEndpoint: 5,
  skipEndpoint: 0,
  hasMore: false,
  count: 0,
  mode: "add",
  showGenerateNewApiKeyModal: false,
  confirmStep: 0,
  endpointDetail: null,
  waasUrl: "",
  detailsStatusBasedChart: [],
  networks: [],
  blockchains: [],
  networksListOptions: [],
  blockchainsListOptions: [],
  apiKey: null,
  sendSuccessfulEmailAlertModal: false,
  detailsCuBasedChart: [],
  endpoints: [],
  endpointsOptions: [],

};

export default function Reducer(state = initState, action) {
  switch (action.type) {

    case types.SET_SHOW_ENDPOINT_DETAIL_MODAL:
      return {
        ...state,
        showEndpointDetailModal: action.data.visible,
        mode: action.data.mode,
      };
    case types.GET_ENDPOINTS_LIST:
      return {
        ...state,
        endpointLoading: true,

      };
    case types.GET_ENDPOINTS_LIST_SUCCESS:
     
      let row = {
        apiKey: "",
        id: "",
        matchingUrl: action.data.data.waasUrl,
        name: action.data.data.waasName,
        network: "-",
        endpointType: "-",
        statusPercentage: action.data.data.waasStatusPercentage,
        waasApiKeyId : action.data.data.waasApiKeyId 
      }

      let newendpointsList = [...state.endpointsList];
      if(action.data.page === 1){
        newendpointsList = [row, ...action.data.data.nodes.items];
      }
      else{
        newendpointsList = [...action.data.data.nodes.items];
      }
      
      return {
        ...state,
        endpointLoading: false,
        endpointsList: newendpointsList,
        skipEndpoint: action.data.skip + state.takeEndpoint,
        count: action.data.data.nodes.totalItemsCount,
        waasUrl: action.data.data.waasUrl,
        hasMore: action.data.skip + state.takeEndpoint < action.data.data.nodes.totalItemsCount ? true : false,
      };

    case types.GET_ENDPOINT_BY_ID_SUCCESS:
      return {
        ...state,
        endpointDetail: action.data
      }

    // case types.ADD_ENDPOINT_SUCCESS:

    //   let row1 = {
    //     apiKey: "",
    //     id: "",
    //     matchingUrl: action.data.data.waasUrl,
    //     name: "سرویس کیف پول",
    //     network: "-",
    //     endpointType: "-",
    //     statusPercentage: action.data.data.waasStatusPercentage
    //   }
    //   let newendpointsList1 = [];
    //   newendpointsList1 = [row1, ...action.data.data.nodes.items];
    //   return {
    //     ...state,
    //     endpointLoading: false,
    //     endpointsList: newendpointsList1,
    //     skipEndpoint: action.data.skip + state.takeEndpoint,
    //     count: action.data.data.nodes.totalItemsCount,
    //     waasUrl: action.data.data.waasUrl,
    //     hasMore: action.data.skip + state.takeEndpoint < action.data.data.nodes.totalItemsCount ? true : false,
    //     showEndpointDetailModal: false,
    //     mode: "add",
    //   };

      case types.ADD_ENDPOINT_SUCCESS:
        return {
          ...state,
          endpointsList: RecordController.addATIndex1(
            state.endpointsList,
            action.data.data
          ),
          showEndpointDetailModal: false,
          mode: "add",
        };

    case types.SET_SHOW_GENERATE_API_KEY_MODAL:
      return {
        ...state,
        showGenerateNewApiKeyModal: action.data.visible
      };
    case types.GET_DETAILS_STATUS_BASED_CHART_SUCCESS:
      return {
        ...state,
        detailsStatusBasedChart: action.data
      };
    case types.GET_NETWORKS_LIST_SUCCESS:
      return {
        ...state,
        networks: action.data.data,
        networksListOptions: prepareSelectData("endpoint", action.data.data)
      };
    case types.GET_BLOCKCHAIN_LIST_SUCCESS:
      return {
        ...state,
        blockchains: action.data.data,
        blockchainsListOptions: prepareSelectData("endpoint", action.data.data)
      };
    case types.GENERATE_APIKEY_SUCCESS:
      return {
        ...state,
        apiKey: action.data.data,
        confirmStep: 1,
        showGenerateNewApiKeyModal: false,
        sendSuccessfulEmailAlertModal: true,
      };
    case types.SET_SHOW_API_KEY_MODAL:
      return {
        ...state,
        apiKey: null,
        confirmStep: 0,
        sendSuccessfulEmailAlertModal: action.data.visible,
      };
    case types.DOWNLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
      };
      case types.GET_DETAILS_CU_BASED_CHART_SUCCESS:
        return {
          ...state,
          detailsCuBasedChart : action.data
        };
        case types.GET_ENDPOINTS_SUCCESS:
          return {
            ...state,
            endpoints: action.data.data,
            endpointsOptions: prepareSelectData("endpoints", action.data.data)
          };
    default:
      return state;
  }
}