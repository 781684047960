

import React, { useState } from "react";
import EndpointsActions from "src/redux/Endpoint/Actions";
import { useDispatch, useSelector } from "react-redux";
import { TableWrapper, CardContainer, AddContainer } from "src/shared/generalStyles";
import Table from "src/shared/table";
import EndpointTableConfig from "./EndpointTableConfig";
import EndpointDetailModal from "./EndpointDetailModal";
import { FilterContainer } from "../styles";
import EndpointFilter from "./EndpointFilter"
import GenerateNewApiKeyModal from "src/views/modal/generateNewApiKey"
import {  Grid } from "@mui/material";
import AppActions from "src/redux/App/Actions";
import { downloadFile } from "src/utils/methods/DownloadFileHandler";

const Endpoint = () => {

  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const endpointState = useSelector((state) => state.Endpoint);
  const {takeEndpoint ,skipEndpoint, endpointLoading, showEndpointDetailModal, count, mode, showGenerateNewApiKeyModal, endpointsList } = endpointState;

  const defaultfields = {
    id: "",
    title: "",
    network: "",
    blockchain :"",
    endpointType:""
  };
  const [fields, setFields] = useState(defaultfields);
  const [filterState, setFilterState] = useState({
    title: "",
    menu_position: "",
    is_active: "",
  });

  const addOrUpdateMenusHandler = (values, action) => {
    let callBack = () => {
      setFields({
        ...fields,
        ...defaultfields,
      });
    };
    if (mode === "add") {
      dispatch(
        EndpointsActions.addEndpoint({
          fields: values,
          callBack
        })
      );
    } else if (mode === "edit") {
      dispatch(
        EndpointsActions.updateEndpoint({
          fields: values,
          callBack
        })
      );
    }
  };

  const closeMenusDetailsModal = (formik) => {
    formik.resetForm({});
    dispatch(
      EndpointsActions.setshowEndpointDetailModal({
        visible: false,
        mode: "add",
      })
    );
    setFields({
      ...fields,
      ...defaultfields,
    });

    formik.resetForm({});
  };
  //////////////////////////////////////
  const pageHandler = (res) => {
    dispatch(
      EndpointsActions.getEndpointsList({
        skip: res,
        take: takeEndpoint,
        is_active: filterState.is_active,
        menu_position: filterState.menu_position,
        title: filterState.title,
        pageSize : takeEndpoint,
        page :(res+takeEndpoint)/takeEndpoint
      })
    );
  }

  /////////////////////////////////
  const showGenerateNewApiKeyHandler = (show) => {
    dispatch(EndpointsActions.setShowGenerateApiKeyModal({ visible: true }))
  }
    /////////////////////////////////
    const setPageInfoHandler = (data) => {
      dispatch(AppActions.setPageInfo( data));
    }
  ////////////////////////////////
  const handleDownload = () => {
    // Simulate fetching PDF data from a local file
    fetch('/files/Polaris NaaS v1.1.pdf')
      .then(response => response.blob())
      .then(blob => {
        downloadFile(blob, "Polaris NaaS v1.1", "pdf");
      })
      .catch(error => {
        console.error('Error downloading the file:', error);
        toast.error("خطا در دانلود فایل.", { position: toast.POSITION.BOTTOM_RIGHT });
      });
  };

  const downloadDocumentHandler = (row) => {
    if(row.id === ""){
      dispatch(EndpointsActions.downloadDocument());
    }
    else{
      handleDownload();
    }
  }
  return (
    <>
      <CardContainer>
        <FilterContainer>
          <Grid container>
            <Grid item md={10} className="filter-box">
              <EndpointFilter filterState={filterState} setFilterState={setFilterState} />
            </Grid>

            <Grid item md={2} >
              <AddContainer>
                <span onClick={() => {
                  dispatch(
                    EndpointsActions.setshowEndpointDetailModal({
                      visible: true,
                      mode: "add",
                    })
                  );
                }}>
                  نقطه اتصال جدید
                </span>
              </AddContainer>
            </Grid>
          </Grid>

        </FilterContainer>
        <TableWrapper height="calc(100% - 60px)" id="endpoint-table">
          <Table
            tblConfig={EndpointTableConfig(showGenerateNewApiKeyHandler ,setSelected ,setPageInfoHandler ,downloadDocumentHandler)}
            tblData={endpointsList}
            showPaging={true}
            pageHandler={(res) => {
              pageHandler(res);
            }}
            skip={skipEndpoint}
            take={takeEndpoint}
            count={count}
            loading={endpointLoading}
          />
        </TableWrapper>
      </CardContainer>
      <EndpointDetailModal
        show={showEndpointDetailModal}
        title={
          mode === "add" ? "اضافه کردن نقطه اتصال " : "جزییات نقطه اتصال"
        }
        onclose={closeMenusDetailsModal}
        onConfirm={addOrUpdateMenusHandler}
        fields={fields}
        setFields={setFields}
      />
      <GenerateNewApiKeyModal open={showGenerateNewApiKeyModal} title={` نقطه اتصال نود`+" "+selected?.name} selected={selected}  />
    </>
  )
}

export default Endpoint;